import React, { useState } from 'react'
import get from 'lodash/fp/get'
import merge from 'lodash/fp/merge'
import { MdOutlineStar, MdMoreHoriz } from 'react-icons/md'

import {
  getImageUrl,
  getCheckInOut,
  packageAddonsHaveImages,
  orderPackageAddons,
} from '../../../common'
import { IEventComponentOffering } from '../../../types'
import { offeringTypes } from '../../../common/constants'
import { OfferingModal } from '../../../components'
import { PackageAddonsImages } from '../../Booking/components'

import { CTAbook } from './CTAbook'
import { CustomizeComponent } from './CustomizeComponent'
import { OfferingInfo } from './style'
import { useGetExtraDaysFromAddons } from './rsvpFunctions'

export const EventComponentOffering: React.FC<IEventComponentOffering> = ({
  eventComponent,
  hideCTA = false,
  hideText = false,
  isMyEvent = false,
  showMainIcon,
  big = false,
  customSize,
  externalValidation,
  guest,
  style = {},
  className = '',
  currentAddons,
  bookingData,
  children,
}) => {
  const [showModal, setShowModal] = useState(false)
  const extraDays = useGetExtraDaysFromAddons(currentAddons)

  const { offering, alias, main, id, packageCustomization } = eventComponent
  const date = bookingData?.date ?? eventComponent.date
  const duration = bookingData?.duration ?? eventComponent.duration
  const pc = orderPackageAddons(packageCustomization)
  const imageSize = big ? '520x320' : '400x225'
  const image = getImageUrl(
    get('[0].offeringAddOn.images[0]', pc) || offering.mainImage,
    customSize || imageSize
  )
  const { packageOffering } = offeringTypes

  const name = alias || offering?.name
  return (
    <OfferingInfo
      style={merge(style, { backgroundImage: `url(${image})` })}
      className={`${className} ${big ? 'big-slide' : ''}`}
    >
      <div
        className={`overlay ${
          big ? 'justify-content-end' : 'justify-content-start'
        }`}
      >
        <h3
          className={`text-white fwsb w-100 mb-1 ${
            offering.__typename === packageOffering ? 'fz-18' : 'fz-14'
          }`}
        >
          {!hideText && (big ? name : name.split(',')[0])}

          {!big && (
            <span className="fz-11 mt-2 mb-1 text-white date-ec">
              {getCheckInOut({
                date,
                dateFormat: 'MMM DD, YYYY',
                duration,
                extraNights: {
                  before: extraDays.before,
                  after: extraDays.after,
                },
              })}
            </span>
          )}
        </h3>
        <span
          className={`open-modal cursor ${hideCTA && 'd-none'}`}
          onClick={() => setShowModal(true)}
          title="More Details"
        >
          <MdMoreHoriz color="white" size={28} />
        </span>

        {!hideCTA && (
          <OfferingModal
            data={offering}
            show={showModal}
            setShowModal={setShowModal}
            isMyEvent={isMyEvent}
            hideDescription
            optionalCTA={
              <div className="text-center pb-3">
                {children ?? (
                  <CTAbook
                    guest={guest}
                    className="px-5 mt-1 fz-18"
                    eventComponent={eventComponent}
                  />
                )}
              </div>
            }
            customizeComponent={
              <CustomizeComponent
                data={offering}
                isMyEvent={isMyEvent}
                isMain={main}
                eventComponentId={id}
                eventComponentAlias={alias}
              />
            }
            customSlider={
              packageAddonsHaveImages(pc) ? (
                <PackageAddonsImages customizations={pc} />
              ) : undefined
            }
          />
        )}

        {externalValidation}
        {!(isMyEvent && offering.__typename === packageOffering) &&
          !externalValidation &&
          !hideCTA &&
          (children ?? (
            <CTAbook
              guest={guest}
              className="px-3 py-0 mt-1"
              eventComponent={eventComponent}
            />
          ))}
        {showMainIcon && main && (
          <MdOutlineStar className="main-component-icon" color="white" />
        )}
      </div>
    </OfferingInfo>
  )
}

import React from 'react'
import find from 'lodash/fp/find'
import Button from 'react-bootstrap/Button'
import { useQuery } from '@apollo/react-hooks'

import { UserPicture, DefaultUserPicture } from '../../components'
import { getMoreData } from '../../common'
import { useAppSelector } from '../../hooks'
import { MyEvent, Event, EventComponentGroup, IRSVPcards } from '../../types'
import { GET_FEED_COMPONENTS } from '../../graphql'

import { DefaultCards } from './DefaultCards'
import { RSVPbanner, Components } from './components'
import { RSVPCard } from './style'

const RSVPcards: React.FC<IRSVPcards> = ({ myEvents, otherEvents }) => {
  const { currentEventId } = useAppSelector(state => state.site)
  const { data, loading, fetchMore } = useQuery(GET_FEED_COMPONENTS, {
    variables: { eventId: currentEventId },
    fetchPolicy: 'network-only',
  })
  /**
   * I was going to update the cache when the Booking instance is created
   * but there are many conditions plus the fact that there can be more than one group
   * with the same date and I have no id to filter them only date
   * so for the moment using network-only is a better option
   */

  const cursorToken: string = data?.feedComponentGroups?.cursor
  const entries: EventComponentGroup[] =
    data?.feedComponentGroups?.entries || []
  const events: (MyEvent | Event)[] = [...myEvents, ...otherEvents]
  const myBookings = data?.me?.myBookings

  const loadMore = () => {
    if (cursorToken && !loading) {
      getMoreData({
        fetchMore,
        query: GET_FEED_COMPONENTS,
        cursorToken,
        path: 'feedComponentGroups',
      })
    }
  }

  return (
    <div className="d-flex flex-wrap justify-content-center">
      {entries.map(el => {
        const event = find({ id: el.event.id }, events)
        const isMyEvent = find({ id: el.event.id }, myEvents)
        const { firstName, avatarUrl } = el.event.host

        // priority order: eventImage > avatarUrl > defaultUserPicture
        const eventImage = el.event.mainImage || avatarUrl
        return (
          <RSVPCard className="rsvp-card" key={`rsvp-card-${el.event.id}`}>
            {eventImage ? (
              <UserPicture name={`${firstName}`} url={eventImage} />
            ) : (
              <DefaultUserPicture />
            )}
            <h2 className="fz-15 mb-0 pt-2 fwsb">{event?.name}</h2>
            <Components
              components={el.components}
              isMyEvent={!!isMyEvent}
              myBookings={myBookings}
            />
          </RSVPCard>
        )
      })}
      {cursorToken && (
        <Button
          disabled={loading}
          className="bradius-1 btn-sm mb-4 btn-outline-salmon-simple"
          onClick={() => loadMore()}
        >
          Load more
        </Button>
      )}

      <DefaultCards events={events} feedComponentGroups={entries} />
      <RSVPbanner />
    </div>
  )
}

export default React.memo(RSVPcards)
